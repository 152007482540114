@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
  margin: 2px 0;
}

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Merienda';
  src: url('/fonts/Merienda/Merienda-Regular.ttf');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Norican';
  src: url('/fonts/Norican/Norican-Regular.ttf');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

:root {
  --font-base: Inter, sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  font-family: var(--font-base);
}

body.intercom-h-0 {
  @media only screen and (max-width: 450px) {
    height: 0px !important;
  }
}

.SnackbarContent-root {
  &.snackbar-container {
    .SnackbarItem-message {
      width: 100%;
    }
    .SnackbarItem-action {
      position: absolute;
      top: 10px;
      right: 10px;
      margin: 0;
      padding: 0;
    }
  }
}

.modal-center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ps-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.2rem;
  transform-origin: 0%;
  z-index: 99;
}

.ps-hash-span {
  margin-top: -105px;
  padding-bottom: 95px;
  display: none;
}

.scaptcha-anchor-container {
  max-width: none !important;
  border-radius: 0.5rem !important;
  background-color: rgba(0, 27, 41, 0.1) !important;
  border: none !important;
}

.scaptcha-card-container {
  border: 2px solid #f5a302;
  border-radius: 0.25rem;
  min-width: 320px !important;
  top: 25% !important;
}

.scaptcha-card-background,
.scaptcha-card-slider-track,
.scaptcha-card-slider-container .scaptcha-card-slider-label {
  width: 320px !important;
}
.scaptcha-card-slider-container {
  width: 320px !important;
}
.scaptcha-card-slider-label {
  font-weight: bold !important;
  color: rgba(0, 27, 41, 0.8) !important;
}

.scaptcha-card-slider-track-default,
.scaptcha-card-slider-track-active,
.scaptcha-card-slider-control-active {
  background-color: #f5a302 !important;
}

.scaptcha-card-slider-track-success,
.scaptcha-card-slider-control-success {
  background-color: #43b33e !important;
}

.scaptcha-card-slider-track-failure,
.scaptcha-card-slider-control-failure {
  background-color: #e90024 !important;
}

.scaptcha-anchor-checkbox {
  border: 2px solid rgba(0, 27, 41, 0.6) !important;
}

.scaptcha-anchor-label {
  font-family: var(--font-base) !important;
  font-size: 14px !important;

  color: rgba(255, 255, 255, 0.8) !important;
}

@media screen and (max-width: 639px) {
  .scaptcha-card-container {
    left: 80% !important;
  }
}

@media screen and (max-width: 480px) {
  .scaptcha-card-container {
    left: 103% !important;
  }
}

.yarl__thumbnails_thumbnail {
  border-radius: 8px !important;
  padding: 0 !important;
}

.yarl__thumbnails_thumbnail_active {
  border: 2px solid #f5a302 !important;
}
.yarl__counter {
  color: #f5a302 !important;
}

@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }

  /*localhost*/
  .intercom-dfosxs {
    bottom: 72px !important;
  }

  iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
  }
}
